/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button
} from '@material-ui/core';
import { Typography as SwagupTypography, Typography } from '@swagup-com/components';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ClearIcon from '@material-ui/icons/Clear';
import { Helmet } from '../../components/shared';
import seoTags from '../../apis/seoTags';
import { NavigateTo } from '../shared/ui/common';
import TypeFormExpertModal from '../shared/ui/typeFormExpertModal';
import { CommonButton } from '../shared/ui/commonButtons';
import { shoppingExperienceColors } from '../shared/constants';
import { RightArrowIcon } from '../shared/icons';
import useResponsive from '../hooks/useResponsive';
import { useCartContext } from '../context/cart.context';
import SwagupProcessStepper from '../shared/ui/steper';

const img1 =
  'https://swagupstack-swaguphr.s3.us-east-1.amazonaws.com/uat/cdn/images/new-shopping-experience/common/start-project-Free-Delivery-Shipping-Box-Mockup.png';
const img2 =
  'https://swagupstack-swaguphr.s3.us-east-1.amazonaws.com/uat/cdn/images/new-shopping-experience/common/start-project-mugs.png';

const redirect = () => {
  window.open('https://www.swagup.com/book-time-with-me', '_blank');
};

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
    height: '435px'
  },
  image: {
    height: '210px',
    width: '250px',
    objectFit: 'contain'
  },
  imageTablet: {
    height: '150px',
    width: '150px',
    objectFit: 'contain'
  },
  subText: {
    margin: '20px 0px',
    paddingTop: '16px'
  },
  mainContainer: {
    margin: '0px 155px',
    [theme.breakpoints.down('xl')]: {
      margin: '0px 320px'
    },
    [theme.breakpoints.down('lg')]: {
      margin: '0px 100px'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px 20px'
    }
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'start',
    [theme.breakpoints.down('xl')]: {
      justifyContent: 'space-between',
      padding: '0px 50px'
    },
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'space-between',
      padding: '0px',
      marginLeft: '46px',
      marginRight: '46px'
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      padding: '0px',
      marginLeft: '0px',
      marginRight: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      justifyContent: 'space-evenly'
    }
  },
  cards: {
    padding: 48,
    background: shoppingExperienceColors.cardsBGColor,
    textAlign: 'center',
    border: `1px solid ${shoppingExperienceColors.checkboxOutline}`,
    borderRadius: 10,
    [theme.breakpoints.down('xl')]: {
      margin: 20
    },
    [theme.breakpoints.down('lg')]: {
      margin: 20
    },
    [theme.breakpoints.down('md')]: {
      padding: 12,
      margin: 0
    },
    [theme.breakpoints.down('sm')]: {
      padding: 12,
      margin: 0
    }
  },
  cardsImage: {
    height: 300,
    width: 404,
    [theme.breakpoints.down('md')]: {
      height: 200,
      width: 300
    },
    [theme.breakpoints.down('sm')]: {
      height: 275
    }
  },
  cardAccordian: {
    background: 'none',
    borderRadius: 0,
    boxShadow: 'none'
  },
  accordianContainer: {
    background: 'none',
    borderRadius: '0 !important',
    boxShadow: 'none'
  },
  accordianSummary: {
    margin: '0px !important',
    height: 10
  },
  accordianText: {
    color: shoppingExperienceColors.cardCtaHover,
    fontWeight: 600
  },
  accordianDescText: {
    color: shoppingExperienceColors.cardCtaHover,
    fontWeight: 400
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0
  },
  faqAcccordianContainer: {
    marginTop: 40,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '92%'
    }
  },
  faqWrapper: {
    padding: '0px 48px',
    [theme.breakpoints.down('xl')]: {
      margin: '0px 20px 20px 20px'
    },
    [theme.breakpoints.down('lg')]: {
      margin: '0px 20px 20px 20px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px 0px',
      margin: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px',
      margin: '0px'
    }
  },
  faqAccordian: {
    borderRadius: '10px !important',
    border: `1px solid ${shoppingExperienceColors.textPrimary}`,
    marginBottom: 20,
    boxShadow: 'none',
    padding: '20px 24px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 0
    }
  },
  supportButton: {
    background: shoppingExperienceColors.primary,
    width: 130,
    height: 41,
    borderRadius: 4
  },
  adImage: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 291
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 341
    }
  }
}));

const ProjectOnboarding = () => {
  const classes = useStyles();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const { isTablet } = useResponsive();
  const [width] = useState(window.innerWidth);
  const isMobile = Math.min(width) < 786;
  const isTouchDevice = Math.min(width) < 1025 && Math.min(width) > 786;
  const { setCartTypeToPack, setCartTypeToBulk } = useCartContext();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const list = (data, bulk = false) => {
    return (
      <List>
        {data.map((item, index) => (
          <ListItem key={index} className={classes.listItem}>
            <ListItemIcon>
              {index == 0 ? (
                !bulk ? (
                  <StarBorderOutlinedIcon style={{ color: 'black' }} />
                ) : (
                  <ClearIcon style={{ color: 'black' }} />
                )
              ) : (
                <CheckCircleIcon />
              )}
            </ListItemIcon>
            <div style={{ display: 'block' }}>
              <ListItemText
                primary={
                  <Typography variant="body2Medium" className={classes.accordianText}>
                    {item.title}
                  </Typography>
                }
              />
              {!isMobile && !isTouchDevice && (
                <ListItemText
                  primary={
                    <Typography variant="body2Medium" className={classes.accordianDescText}>
                      {item.desc}
                    </Typography>
                  }
                />
              )}
            </div>
          </ListItem>
        ))}
      </List>
    );
  };

  const faqAccordian = () => {
    const [expandSwagProcess, setExpandSwagProcess] = useState(false);
    const [expandAssistance, setExpandAssistance] = useState(false);

    const handleExpandSwagProcess = (event, newExpanded) => {
      setExpandSwagProcess(newExpanded);
    };

    const handleExoandAssistance = (event, newExpanded) => {
      setExpandAssistance(newExpanded);
    };

    const assistanceChecklist = [
      {
        title: 'Customized Swag Tailored to Your Needs',
        desc:
          'Work with your dedicated Swag Expert to create unique, custom items that perfectly represent your brand and meet your specific goals.'
      },
      {
        title: 'Expert Guidance from Start to Finish',
        desc:
          'Not sure where to start? Your Swag Expert will guide you through the entire process, from product selection to final delivery, ensuring everything runs smoothly.'
      },
      {
        title: 'Streamlined Process for Stress-Free Ordering',
        desc:
          'Save time and reduce hassle by having a single point of contact who handles all the details, making your swag experience effortless and efficient.'
      }
    ];

    return (
      <Grid className={classes.faqWrapper}>
        <Accordion className={classes.faqAccordian} expanded={expandSwagProcess} onChange={handleExpandSwagProcess}>
          <AccordionSummary
            expandIcon={
              expandSwagProcess ? (
                <RemoveCircleIcon style={{ color: shoppingExperienceColors.black }} />
              ) : (
                <AddCircleIcon style={{ color: shoppingExperienceColors.primary }} />
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6SemiBoldInter">Explore the Swag Process</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SwagupProcessStepper isMobile={isMobile} />
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.faqAccordian}
          expanded={expandAssistance}
          onChange={handleExoandAssistance}
          style={{ marginBottom: 40 }}
        >
          <AccordionSummary
            expandIcon={
              expandAssistance ? (
                <RemoveCircleIcon style={{ color: shoppingExperienceColors.black }} />
              ) : (
                <AddCircleIcon style={{ color: shoppingExperienceColors.primary }} />
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6SemiBoldInter">Need Assistance?</Typography>
          </AccordionSummary>
          <AccordionDetails style={isMobile ? { display: 'flex', flexDirection: 'column-reverse' } : {}}>
            <Grid lg={6} md={6}>
              <Typography variant="h5" style={{ fontFamily: 'inter' }}>
                CONTACT OUR <span style={{ color: shoppingExperienceColors.primary }}>SWAG EXPERTS</span>
              </Typography>
              <List>
                {assistanceChecklist.map((item, index) => (
                  <ListItem key={index} className={classes.listItem}>
                    <ListItemIcon>
                      <CheckCircleIcon style={{ color: shoppingExperienceColors.ctaHover }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          <Typography variant="body2Medium" className={classes.accordianText}>
                            {item.title}
                          </Typography>
                          <Typography variant="body3RegularInter">{item.desc}</Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              <Button
                variant="contained"
                className={classes.supportButton}
                onClick={() => {
                  redirect();
                }}
              >
                <Typography variant="body4RegularInter" style={{ color: shoppingExperienceColors.white }}>
                  Get Support
                </Typography>
              </Button>
            </Grid>
            <Grid lg={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <img
                src="https://swagupstack-swaguphr.s3.us-east-1.amazonaws.com/uat/cdn/images/new-shopping-experience/common/start-project-ad.png"
                alt="no-image"
                className={classes.adImage}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };

  const StartProjectCards = () => {
    const [itemsAccordionExpanded, setItemsAccordionExpanded] = useState(false);
    const [bulkAccordionExpanded, setBulkAccordionExpanded] = useState(false);

    const handleItemsAccordionChange = (event, newExpanded) => {
      setItemsAccordionExpanded(newExpanded);
    };

    const handleBulkAccordionChange = (event, newExpanded) => {
      setBulkAccordionExpanded(newExpanded);
    };

    const packItems = [
      { title: 'Perfect for Onboarding packs, to celebrate milestones, client gifts.', desc: '' },
      {
        title: 'Assembly & Packaging',
        desc:
          'We take care of packing every item for your team, ensuring a smooth experience. You no longer need to worry about assembly – we handle it all for you.'
      },
      {
        title: 'Warehouse & Inventory Management',
        desc:
          'Our Warehouse & Inventory Management service optimizes storage, tracking, and order fulfillment, ensuring accuracy, efficiency, and timely deliveries.'
      },
      {
        title: 'Dedicated Swag Expert',
        desc:
          'Personalized support from start to finish. You’ll be assigned a dedicated expert who will guide you through the process and serve as your main point of contact, ensuring a smooth and seamless experience.'
      }
    ];
    const bulkItems = [
      { title: 'Perfect for events, office swag, client gifts.', desc: '' },
      {
        title: 'Assembly & Packaging',
        desc:
          'Bulk orders are delivered in their original format without individual packaging. You’ll receive your items in bulk, ready for your team to handle as needed.'
      },
      {
        title: 'Warehouse & Inventory Management',
        desc:
          'Our Warehouse & Inventory Management service optimizes storage, tracking, and order fulfillment, ensuring accuracy, efficiency, and timely deliveries.'
      },
      {
        title: 'Dedicated Swag Expert',
        desc:
          'Personalized support from start to finish. You’ll be assigned a dedicated expert who will guide you through the process and serve as your main point of contact, ensuring a smooth and seamless experience.'
      }
    ];

    return (
      <Grid container className={classes.mainContainer}>
        <Grid className={classes.cardWrapper}>
          <Grid
            md={6}
            lg={6}
            xl={6}
            sm={11}
            xs={11}
            className={classes.cards}
            style={isTouchDevice ? { marginRight: 10 } : isMobile ? { marginBottom: 20 } : {}}
          >
            <Typography variant="subtitle1RegularInter">CUSTOM SWAG PACKS</Typography>
            <Typography variant="body3RegularInter" style={{ color: shoppingExperienceColors.primary }}>
              Get Free Designs & Mockups within 24 hours!
            </Typography>
            <img src={img1} alt="no-image" className={classes.cardsImage} />
            <CommonButton
              id="customSwagPack"
              text="Start a Pack - It's Free"
              px={40}
              isActive
              mr={10}
              hasIcon
              icon={<RightArrowIcon color="#ffff" />}
              onClick={() => {
                setCartTypeToPack();
                NavigateTo(history, '/catalog/collections/best-sellers?cartType=pack');
              }}
            />
            <Grid
              style={{ padding: '20px 20px 0px 20px', cursor: 'pointer' }}
              onClick={() => {
                NavigateTo(history, '/preset-packs');
              }}
            >
              <Typography variant="buttonMediumInter">Explore Preset Packs</Typography>
            </Grid>
            <Card className={classes.cardAccordian}>
              <CardContent style={{ padding: 0 }}>
                <Accordion
                  expanded={itemsAccordionExpanded}
                  onChange={handleItemsAccordionChange}
                  className={classes.accordianContainer}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.accordianSummary}
                  >
                    <Button
                      onClick={() => setItemsAccordionExpanded(!itemsAccordionExpanded)}
                      style={{ marginTop: '10px', display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                      <Typography variant="body3MediumInter">Learn more</Typography>{' '}
                      {itemsAccordionExpanded ? (
                        <ExpandMoreIcon style={{ transform: 'rotate(180deg)', marginLeft: 5, color: 'black' }} />
                      ) : (
                        <ExpandMoreIcon style={{ marginLeft: 5, color: 'black' }} />
                      )}
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails style={{ padding: '4px 0px 0px' }}>{list(packItems, false)}</AccordionDetails>
                </Accordion>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            md={6}
            lg={6}
            xl={6}
            sm={11}
            xs={11}
            className={classes.cards}
            style={isTouchDevice ? { marginLeft: 10 } : isMobile ? { marginTop: 20 } : {}}
          >
            <Typography variant="subtitle1RegularInter">BULK PRODUCTS FOR ANY USE</Typography>
            <Typography variant="body3RegularInter" style={{ color: shoppingExperienceColors.primary }}>
              Get Free Designs & Mockups within 24 hours!
            </Typography>
            <img src={img2} alt="no-image" className={classes.cardsImage} />
            <CommonButton
              id="bulkItems"
              text="Browse Products"
              px={40}
              isActive
              mr={10}
              hasIcon
              icon={<RightArrowIcon color="#ffff" />}
              onClick={() => {
                setCartTypeToBulk();
                NavigateTo(history, '/catalog/collections/best-sellers?cartType=bulk');
              }}
            />
            <Grid
              style={{ padding: '20px 20px 0px 20px', cursor: 'pointer' }}
              onClick={() => {
                redirect();
              }}
            >
              <Typography variant="buttonMediumInter">Send a Special Request</Typography>
            </Grid>
            <Card className={classes.cardAccordian}>
              <CardContent style={{ padding: 0 }}>
                <Accordion
                  expanded={bulkAccordionExpanded}
                  onChange={handleBulkAccordionChange}
                  className={classes.accordianContainer}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.accordianSummary}
                  >
                    <Button
                      onClick={() => setBulkAccordionExpanded(!bulkAccordionExpanded)}
                      style={{ marginTop: '10px', display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                      <Typography variant="body3MediumInter">Learn more</Typography>{' '}
                      {bulkAccordionExpanded ? (
                        <ExpandMoreIcon style={{ transform: 'rotate(180deg)', marginLeft: 5, color: 'black' }} />
                      ) : (
                        <ExpandMoreIcon style={{ marginLeft: 5, color: 'black' }} />
                      )}
                    </Button>
                  </AccordionSummary>
                  <AccordionDetails>{list(bulkItems, true)}</AccordionDetails>
                </Accordion>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid className={classes.faqAcccordianContainer}>{faqAccordian()}</Grid>
      </Grid>
    );
  };

  return (
    <>
      <Helmet tags={seoTags.projectOnboarding} />
      <Grid container>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ flexDirection: 'column', gap: '20px', marginBottom: '20px' }}
        >
          <Grid item style={{ textAlign: 'center' }}>
            <SwagupTypography variant="h2SemiBoldInter">Let's start your swag project</SwagupTypography>
          </Grid>
          <Grid item>
            <SwagupTypography variant="body2RegularInter">Select one of the options below:</SwagupTypography>
          </Grid>
        </Grid>
        <StartProjectCards />
      </Grid>
      <TypeFormExpertModal open={openModal} onClose={handleCloseModal} />
    </>
  );
};

export default ProjectOnboarding;
