import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { StepConnector } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { shoppingExperienceColors } from '../constants';

// Custom Styling
const useStyles = makeStyles(theme => ({
  stepper: {
    background: 'transparent', // Remove default background
    padding: '20px 0',
    position: 'relative',
    width: '100%'
  },
  stepIcon: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: shoppingExperienceColors.primary,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    zIndex: 10
  },
  stepLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '8px'
  },
  customStepLabel: {
    backgroundColor: 'white',
    color: shoppingExperienceColors.primary,
    padding: '4px 12px',
    borderRadius: '20px',
    border: `1px solid ${shoppingExperienceColors.primary}`,
    fontSize: '12px',
    textAlign: 'center',
    position: 'absolute',
    zIndex: 1
  },
  customConnector: {
    borderColor: shoppingExperienceColors.primary,
    borderStyle: 'dotted',
    [theme.breakpoints.down('sm')]: {
      borderStyle: 'none'
    }
  },
  dummyText: {
    marginTop: '8px', // Space between the icon and text
    fontSize: '12px',
    color: shoppingExperienceColors.primary,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0px' // Space between the icon and text
    }
  },
  stepTitle: {
    fontFamily: 'inter',
    fontSize: 24,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left !important',
      marginLeft: '10px !important',
      width: '200px !important'
    }
  }
}));

// Custom Connector to style the line between steps
const CustomConnector = withStyles(theme => ({
  alternativeLabel: {
    top: 10
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main // Use theme's primary color
    }
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main // Use theme's primary color
    }
  },
  line: {
    borderTopWidth: 1,
    borderColor: theme.palette.primary.main, // Use theme's primary color
    borderStyle: 'dotted',
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    [theme.breakpoints.down('sm')]: {
      borderTopWidth: 0
    }
  }
}))(StepConnector);
// Steps Data
const steps = [{ label: 'This Process is FREE!' }, { label: 'Got Questions? Get Full Support' }];

const SwagupProcessStepper = props => {
  const { isMobile } = props;
  const classes = useStyles();
  return (
    <Stepper
      alternativeLabel
      className={classes.stepper}
      connector={<CustomConnector />}
      orientation={isMobile && 'vertical'}
    >
      <Step style={isMobile ? { display: 'flex' } : {}}>
        <StepLabel StepIconComponent={() => <div className={classes.stepIcon}>1</div>} />
        <div className={classes.dummyText}>
          <Typography variant="h5" className={classes.stepTitle} style={{ textAlign: 'center' }}>
            SELECT SWAG PROJECT
          </Typography>
          <Typography
            variant="body4RegularInter"
            style={isMobile ? { textAlign: 'left', marginLeft: 10 } : { textAlign: 'center' }}
          >
            Choose one of our options above: start building a custom pack or select items in bulk for any occasion!
          </Typography>
        </div>
      </Step>
      <Step style={isMobile ? { display: 'flex', marginTop: 40 } : {}}>
        <StepLabel
          StepIconComponent={() => (
            <div
              className={classes.customStepLabel}
              style={isMobile ? { left: 3, top: -20, width: 140 } : { left: 0, top: -10, width: 140 }}
            >
              <Typography variant="body4MediumInter" style={{ color: shoppingExperienceColors.primary }}>
                This Process is FREE!
              </Typography>
            </div>
          )}
        />
      </Step>
      <Step style={isMobile ? { display: 'flex', marginTop: 40 } : {}}>
        <StepLabel StepIconComponent={() => <div className={classes.stepIcon}>2</div>} />
        <div className={classes.dummyText}>
          <Typography variant="h5" className={classes.stepTitle} style={{ textAlign: 'center' }}>
            SELECT YOUR PRODUCTS
          </Typography>
          <Typography
            variant="body4RegularInter"
            style={isMobile ? { textAlign: 'left', marginLeft: 10 } : { textAlign: 'center' }}
          >
            With over 20,000 products and a wide range of brands to choose from, explore our collections and find the
            perfect item for your needs!
          </Typography>
        </div>
      </Step>
      <Step style={isMobile ? { display: 'flex', marginTop: 40 } : {}}>
        <StepLabel
          StepIconComponent={() => (
            <div
              className={classes.customStepLabel}
              style={isMobile ? { left: 3, top: -20, width: 140 } : { left: 3, top: -10, width: 140 }}
            >
              <Typography variant="body4MediumInter" style={{ color: shoppingExperienceColors.primary }}>
                Got Questions? Get Full Support
              </Typography>
            </div>
          )}
        />
      </Step>
      <Step style={isMobile ? { display: 'flex', marginTop: 40 } : {}}>
        <StepLabel StepIconComponent={() => <div className={classes.stepIcon}>3</div>} />
        <div className={classes.dummyText}>
          <Typography variant="h5" className={classes.stepTitle} style={{ textAlign: 'center' }}>
            RECEIVE MOCKUPS
          </Typography>
          <Typography
            variant="body4RegularInter"
            style={isMobile ? { textAlign: 'left', marginLeft: 10 } : { textAlign: 'center' }}
          >
            Get free designs and mockups within 24 hours! Our dedicated team of experts will craft and design according
            to your needs.
          </Typography>
        </div>
      </Step>
    </Stepper>
  );
};

export default SwagupProcessStepper;
