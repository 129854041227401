import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Typography, Button } from '@swagup-com/components';
import { Link } from '../../shared';
import { shoppingExperienceColors } from '../../shared/constants';
import { useHeaderStyles } from './subCategoriesHeader.styles';
import { NavigateTo, TitleFormatter } from '../../shared/ui/common';
import { convertString } from '../../utils';
import FilterButton from '../FilterAndSortButton/filterAndSortButton';

const useStyles = makeStyles(useHeaderStyles);

const SubCategoriesHeader = props => {
  const classes = useStyles();
  const history = useHistory();

  const {
    categoryName = '',
    subCategoryName = '',
    subCategoriesList,
    groupedFilter,
    setGroupedFilter,
    handleSortChange,
    sortItem
  } = props;

  const isActivePath = (_subCategoryName = 'all') =>
    _subCategoryName === 'all' ? subCategoryName === '' : subCategoryName === _subCategoryName;

  let menuList = [];
  subCategoriesList &&
    subCategoriesList.map(item => {
      menuList.push({
        label: item,
        link: `/catalog/${categoryName}/${item}`,
        isActive: isActivePath(item)
      });
    });

  menuList = menuList.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    return 0;
  });

  const AllItem = {
    label: `All ${TitleFormatter(categoryName)}`,
    link: `/catalog/${categoryName}`,
    isActive: isActivePath('all')
  };

  menuList = [AllItem, ...menuList];

  return (
    <Grid container className={classes.subCategoriesContainer}>
      <Grid item xs={6} alignItems="center">
        {menuList.map(menu => (
          <>
            <Button
              variant="text"
              component={Link}
              to={{ pathname: menu.link }}
              onClick={() => NavigateTo(history, menu.link)}
            >
              <Typography
                variant="body2MediumInter"
                component="div"
                style={{ ...(menu.isActive ? { color: shoppingExperienceColors.primary } : { cursor: 'pointer' }) }}
              >
                {menu.label}
              </Typography>
            </Button>
          </>
        ))}
      </Grid>
      <Grid>
        <FilterButton
          groupedFilter={groupedFilter}
          setGroupedFilter={setGroupedFilter}
          categoryName={categoryName}
          handleSortChange={handleSortChange}
          sortItem={sortItem}
        />
      </Grid>
    </Grid>
  );
};

export default SubCategoriesHeader;
