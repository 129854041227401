import React from 'react';
import { mergeClasses } from '@material-ui/styles';
import { CardContent, Grid, makeStyles, Card, Button, Tooltip } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import ClearIcon from '@material-ui/icons/Clear';
import { QUANTITY_BREAKS_ARRAY } from '../../utils/constants';
import { getApplicablePrice } from '../../utils/productUtil';
import { DollarPriceSymbol } from '../../shared/ui/common';
import Styles from './product.styles';
import { shoppingExperienceColors } from '../../shared/constants';

const useStyles = makeStyles(Styles);

const PricingInfo = props => {
  const { priceDetails, isMobile, openCategoryDrawer } = props;
  const classes = useStyles();

  const getPriceForQuantity = qty => {
    const matched = priceDetails?.find(entry => {
      const param = entry.parameters[0];
      return qty >= param.minimum && qty <= param.maximum;
    });
    return matched ? matched.price[0].value : 'N/A';
  };

  return (
    <div style={isMobile ? { padding: '20px 10px' } : { padding: '20 0px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body3MediumInter">Order more items for a lower price:</Typography>{' '}
        {isMobile && <ClearIcon onClick={openCategoryDrawer} />}
      </div>
      <table cellPadding="10" className={classes.priceTable}>
        <thead>
          <tr className={classes.priceInfoHeader}>
            <th>
              <Typography variant="body3MediumInter">Quantity</Typography>
            </th>
            <th>
              <Typography variant="body3MediumInter">Price Per Item</Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {QUANTITY_BREAKS_ARRAY.map((qty, index) => (
            <tr key={qty} style={index % 2 == 0 ? { background: shoppingExperienceColors.secondaryButton } : {}}>
              <td className={classes.priceInfoRow}>
                <Typography variant="body4RegularInter">
                  {qty}
                  {index === 0 && '(min)'}
                </Typography>
              </td>
              <td className={classes.priceInfoRow}>
                <Typography variant="body4RegularInter">{DollarPriceSymbol(getPriceForQuantity(qty))}</Typography>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Typography variant="body3MediumInter">Pricing includes</Typography>
      <Grid>
        <Typography className={classes.priceNote} variant="body4MediumInter">
          <span style={{ color: shoppingExperienceColors.primary }}>FREE </span>mockup design
        </Typography>
        <Typography className={classes.priceNote} variant="body4MediumInter">
          <span style={{ color: shoppingExperienceColors.primary }}>FREE </span>setup
        </Typography>
        <Typography className={classes.priceNote} variant="body4MediumInter">
          <span style={{ color: shoppingExperienceColors.primary }}>FREE </span>quality inspection
        </Typography>
        <Typography className={classes.priceNote} variant="body4MediumInter">
          Dedicated account manager
        </Typography>
      </Grid>
    </div>
  );
};

export default PricingInfo;
