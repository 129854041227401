const shoppingExperienceColors = {
  primary: '#2663FF',
  secondary: '#9846DD',
  success: '#4CAF50',
  info: '#989EA4',
  warning: '#FF9800',
  error: '#F44336',
  white: '#ffffff',
  black: '#000000',
  textPrimary: '#131415',
  borderLineGray: '#E5E7E8',
  disabledCta: '#B7BBBF',
  ctaHover: '#4A4F54',
  cardCtaHover: '#000000CC',
  primaryBgColor: '#D7E4F6',
  secondayBgColor: '#EADAF8',
  successBgColor: '#C8E6C9',
  warningBgColor: '#FFE0B2',
  errorBgColor: '#FFCDD2',
  secondaryButton: '#F5F5F6',
  bannarBGColor: '#DEF6B0',
  bannarBGColorMobile: '#E5E7E8',
  checkboxOutline: '#D6D8DB',
  toggleblueSwitchTrack: '#125CFF33',
  validationErrorText: '#C62828',
  blueLightHovered: '#3577d414',
  cardsBGColor: '#F5F7FE'
};

const PRODUCTION_TIME = {
  standard: 'Standard',
  rush: 'Rush'
};

const CATALOG_PRODUCT_TYPE = {
  PRODUCT: 'Product',
  PACK: 'Pack'
};

export { shoppingExperienceColors, PRODUCTION_TIME, CATALOG_PRODUCT_TYPE };
