import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import seoTags from '../../apis/seoTags';
import { Helmet } from '../../components/shared';
import { fetchCollectionsDetails } from '../apis';
import CollectionTopGutter from '../components/Collections/collectionTopGutter';
import CollectionsBannerImage from '../components/Collections/collectionsBannerImage';
import CollectionsLisiting from '../components/Collections/collectionsLisiting';
import { getRouteMetadata } from '../utils/getRouteMetadata';
import { useResponsive } from '../hooks';
import { getMediaItemByDevice } from '../shared/helper';
import { getNewURL } from '../shared/ui/common';

const Collections = props => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const { loadedProducts, setLoadedProducts, currentPage, setCurrentPage } = props;

  const [selectedCollectionId, setSelectedCollectionId] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [matchingCollection, setMatchingCollection] = useState();
  const [bannerImage, setBannerImage] = useState('');
  const [selectedCollection, setSelectedCollection] = useState('');
  const { data, isFetched, refetch: collectionRefetch } = useQuery(['collectionsData'], () =>
    fetchCollectionsDetails()
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const collections = data?.productCollections?.collections ?? [];
  // const selectedCollection = decodeURIComponent(location.pathname.split('/').pop());
  const [sortItem, setSortItem] = useState(null);
  const { isMobile, isTablet } = useResponsive();

  useEffect(() => {
    if ('selectedCollections' in params) {
      setSelectedCollection(decodeURIComponent(params.selectedCollections));
    }
  }, [params]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setCurrentPage(parseInt(queryParams.get('page'), 10) || 1);
  }, [location]);

  useEffect(() => {
    setMatchingCollection(collections.find(collection => decodeURIComponent(collection?.slug) === selectedCollection));
  }, [collections, selectedCollection, selectedCollectionId]);

  useEffect(() => {
    if (collections.length) {
      if (selectedCollection === 'collections' || selectedCollection === '') {
        const firstCollection = { ...collections[0] };
        const formattedName = encodeURIComponent(firstCollection.slug.toLowerCase());
        const url = `/catalog/collections/${formattedName}`;
        history.replace(url);
      } else if (matchingCollection) {
        setSelectedCollectionId(matchingCollection._id);
        setPageTitle(`Our ${matchingCollection.name}`);
        const images = matchingCollection.media.filter(mediaItem => mediaItem?.mediaCategory === 'template') || [];
        if (images.length) {
          const image = getMediaItemByDevice(images, isMobile, isTablet);
          setBannerImage(image);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection, collections, matchingCollection, isMobile, isTablet]);

  const handleSortChange = field => {
    const newUrl = getNewURL('sort', JSON.stringify(field));
    const urlObj = new URL(newUrl);
    urlObj.searchParams.delete('page');
    window.history.pushState({}, '', urlObj);
    setLoadedProducts([]);
    setCurrentPage(1);
    setSortItem(field);
    collectionRefetch();
  };

  const metadata = getRouteMetadata();

  return (
    <Grid container>
      {metadata ? (
        <Helmet
          tags={{
            title: metadata?.title,
            description: metadata?.description
          }}
        />
      ) : (
        <Helmet tags={seoTags.shoppingExperienceCollections} />
      )}
      <CollectionTopGutter
        collectionsData={collections}
        isFetched={isFetched}
        matchingCollection={matchingCollection}
        setBannerImage={setBannerImage}
        setMatchingCollection={setMatchingCollection}
        setSelectedCollection={setSelectedCollection}
        setLoadedProducts={setLoadedProducts}
        setCurrentPage={setCurrentPage}
      />
      {bannerImage && <CollectionsBannerImage imageUrl={bannerImage} width="100%" height="340px" />}
      <CollectionsLisiting
        {...props}
        selectedCollectionId={selectedCollectionId}
        pageTitle={pageTitle}
        sortItem={sortItem}
        loadedProducts={loadedProducts}
        setLoadedProducts={setLoadedProducts}
        handleSortChange={handleSortChange}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Grid>
  );
};

export default Collections;
