import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Typography, Button } from '@swagup-com/components';
import { useHistory } from 'react-router-dom';
import { useHeaderStyles } from './header.styles';
import { Link } from '../../shared';
import { updateNameForCategory } from '../../utils';
import './subheader.css';

const Subheader = props => {
  const history = useHistory();
  const { subCategoriesList, setDrawerOpen, drawerOpen } = props;
  const headerClasses = useHeaderStyles();

  function sortCategoriesAndSubCategories(categories) {
    return categories
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      })
      .map(category => {
        const sortedSubCategories = category.subCategories.sort((a, b) => {
          if (a.toLowerCase() < b.toLowerCase()) return -1;
          if (a.toLowerCase() > b.toLowerCase()) return 1;
          return 0;
        });

        return { ...category, subCategories: sortedSubCategories };
      });
  }

  const sortedCategories = subCategoriesList && sortCategoriesAndSubCategories(Object.values(subCategoriesList)[0]);

  useEffect(() => {
    const subCategoryItems = document.querySelectorAll('.subCategoryItem');
    if (drawerOpen) {
      setTimeout(() => {
        subCategoryItems.forEach((item, index) => {
          item.style.transitionDelay = `${index * 0.1}s`;
          item.classList.add('visible');
        });
      }, 100);
    } else {
      subCategoryItems.forEach(item => {
        item.classList.remove('visible');
        item.style.transitionDelay = '0s';
      });
    }
  }, [drawerOpen]);

  const handleMouseLeave = () => {
    setDrawerOpen(false);
    const subCategoryItems = document.querySelectorAll('.subCategoryItem');
    subCategoryItems.forEach(item => {
      item.classList.remove('visible');
      item.style.transitionDelay = '0s';
    });
  };

  return (
    <Grid className={headerClasses.subHeaderContainer} onMouseLeave={handleMouseLeave}>
      {subCategoriesList &&
        sortedCategories?.map(category => {
          const categoryName = category.name;
          const { subCategories } = category;
          const path = categoryName;
          if (categoryName !== '') {
            return (
              <div key={`categoryItem-${category.name}`} style={{ display: 'inline-block', gap: '15px', width: '20%' }}>
                <Button
                  style={{ height: 15, padding: '6px 16px' }}
                  variant="text"
                  component={Link}
                  to={categoryName ? { pathname: categoryName } : undefined}
                  onClick={() => {
                    const currentUrl = window.location.pathname;
                    const index = currentUrl.indexOf('/dashboard/catalog');
                    if (index !== -1) {
                      const newPath = currentUrl.slice(0, index);
                      history.push(`${newPath}/catalog/${path}`);
                      setDrawerOpen(false);
                    }
                  }}
                >
                  <Typography variant="body3SemiBoldInter" style={{ cursor: 'pointer' }}>
                    {categoryName}
                  </Typography>
                </Button>
                <div
                  style={{
                    flexWrap: 'wrap',
                    gap: '15px',
                    marginBottom: '16px',
                    marginTop: '16px'
                  }}
                >
                  {subCategories?.length > 0
                    ? subCategories?.map(subCategory => {
                        const subpath = `${categoryName}/${subCategory}`;
                        return (
                          <div key={subCategory} className="subCategoryItem">
                            <Button
                              style={{ height: 10, justifyContent: 'left', padding: '6px 16px', marginBottom: 16 }}
                              variant="text"
                              component={Link}
                              to={subCategory ? { pathname: subCategory } : undefined}
                              onClick={() => {
                                const currentUrl = window.location.pathname;
                                const index = currentUrl.indexOf('/dashboard/catalog');
                                if (index !== -1) {
                                  const newPath = currentUrl.slice(0, index);
                                  history.push(`${newPath}/catalog/${subpath}`);
                                  setDrawerOpen(false);
                                }
                              }}
                            >
                              <Typography variant="body4MediumInter" style={{ color: '#989EA4', cursor: 'pointer' }}>
                                {subCategory}
                              </Typography>
                            </Button>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            );
          }
          return <></>;
        })}
    </Grid>
  );
};

export default Subheader;
